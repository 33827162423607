import React, { useCallback, useEffect, useState } from "react";
import {
  Row,
  Col,
  Table,
  Dropdown,
  message,
  Form,
  Select,
  Input,
  Button,
  Modal,
} from "antd";
import infoIcon from "../../Assets/infoIcon.svg";
import API_MANAGER from "../../API";
import { useNavigate } from "react-router-dom";
import CustomPaginationWithPageSize from "../../components/common/CustomPaginationWithPageSize";
import HELPERS from "../../utils/helper";
import debounce from "lodash.debounce";
import { render } from "@testing-library/react";

function AllTopDepositUsers({ view_user, view_phone }) {
  const [page, setPage] = useState({
    page: 1,
    limit: 20,
  });
  const [data, setData] = useState();

  const [loading, setLoading] = useState(false);
  const [blockUser, setBlockUser] = useState(null);
  const [redAlertUser, setRedAlertUser] = useState(null);
  const navigate = useNavigate();
  const getData = async () => {
    try {
      setLoading(true);
      const response = await API_MANAGER.getAllTopDepositUsers();

      const decryptedData = HELPERS.decrypt(response?.data?.data);
      console.log(decryptedData, "asdbasddadad");
      setData(decryptedData);

      setLoading(false);
    } catch (error) {
      setLoading(false);

      message.error("Something went wrong!");
    }
  };
  const blockUnblockPlayer = async (player, data) => {
    const confirmBox = window.confirm(
      `are you sure that you want to block ${player?.Name}`
    );
    if (confirmBox === true) {
      const userType = player?.user_type === "Block" ? "User" : "Block";

      try {
        await API_MANAGER.blockUnblockPlayer({
          _id: player?._id,
          user_type: userType,
          remark: data?.remark,
        });
        setBlockUser(null);
        message.success("Updated successfully!");
        getData();
      } catch (error) {
        setBlockUser(null);

        message.error("Something went wrong!");
      }
    }
  };
  const handleRedHighlightUser = async (player, data) => {
    const confirmBox = window.confirm(
      `are you sure that you want to ${
        player?.isRedHighlight ? "remove" : "set"
      } red alert ${player?.Name}`
    );
    if (confirmBox === true) {
      try {
        await API_MANAGER.blockUnblockPlayer({
          _id: player?._id,
          isRedHighlight: player?.isRedHighlight ? false : true,
          remark: data?.remark,
          isUpdateRedAlert: true,
        });
        setRedAlertUser(null);
        message.success("Updated successfully!");
        getData();
      } catch (error) {
        setRedAlertUser(null);

        message.error("Something went wrong!");
      }
    }
  };
  const deletePlayer = async (player) => {
    const confirmBox = window.confirm(`are you sure delete ${player?.Name}`);
    if (confirmBox === true) {
      try {
        await API_MANAGER.deletePlayer(player?._id);
        message.success("User deleted successfully!");
      } catch (error) {
        message.error("Something went wrong!");
      }
    }
  };
  const handleItems = (row) => {
    return [
      {
        key: "1",
        label: (
          <div className="action-dropdown">
            <Row>
              <Col
                className="item"
                span={24}
                onClick={() => {
                  if (view_user) navigate(`/user/view/${row?._id}`);
                }}
              >
                <span>View</span>
              </Col>
            </Row>

            <Row>
              <Col
                onClick={() => {
                  setBlockUser(row);
                }}
                className="item"
                span={24}
              >
                <span>{row?.user_type === "Block" ? "Unblock" : "Block"}</span>
              </Col>
            </Row>
            <Row>
              <Col
                onClick={() => {
                  setRedAlertUser(row);
                }}
                className="item"
                span={24}
              >
                <span>
                  {row?.isRedHighlight ? "Remove Red Alert" : "Set Red Alert"}
                </span>
              </Col>
            </Row>

            {/* <Row>
              <Col
                className="item"
                span={24}
                // onClick={() => withdrawPass(row?.txn_id)}
              >
                <span>Edit</span>
              </Col>
            </Row> */}

            <Row>
              <Col className="item" span={24} onClick={() => deletePlayer(row)}>
                <span>Delete</span>
              </Col>
            </Row>
          </div>
        ),
      },
    ];
  };
  const columns = [
    {
      title: "No.",
      dataIndex: "si",
      width: "80px",
      render: (_, row, index) => {
        return (
          <span className="cursor-pointer">
            {(page?.page - 1) * page?.limit + (index + 1)}
          </span>
        );
      },
    },
    {
      title: "User",
      dataIndex: "userId",
      key: "userId",
      render: (item) => (
        <a target="_blank" href={`/user/view/${item}`}>
          {item}
        </a>
      ),
    },

    // {
    //   title: "Name",
    //   dataIndex: "Name",
    //   key: "Name",
    //   render: (item, row) => (
    //     <>
    //       {view_user ? (
    //         <a className="tableLink" href={`/user/view/${row?._id}`}>
    //           {item}
    //         </a>
    //       ) : (
    //         <>{item}</>
    //       )}
    //     </>
    //   ),
    // },

    {
      title: "Total Deposit",
      dataIndex: "totalAmount",
      key: "totalAmount",
    },
  ];

  useEffect(() => {
    getData(); // Cancel debounce on unmount
  }, [page]);
  return (
    <div>
      <p className="pageHeading">All Users</p>

      <Table
        columns={columns}
        dataSource={data ? data : []}
        pagination={false}
        loading={loading}
        className="table"
        rowKey={"id"}
        style={{ marginTop: "24px", borderRadius: "0px" }}
      />

      <Modal
        centered
        open={blockUser}
        closable={true}
        footer={false}
        onCancel={() => setBlockUser(null)}
        // onOk={() => withdrawPas2(withdrawTxnIdSuccess)}
      >
        <Form
          layout="vertical"
          requiredMark={false}
          onFinish={(data) => blockUnblockPlayer(blockUser, data)}
        >
          <Form.Item
            name={"remark"}
            label="Remark*"
            rules={[{ required: true, message: "Please enter remark." }]}
          >
            <Input placeholder="Enter remark" />
          </Form.Item>
          <Row justify={"end"}>
            <Col>
              <Button htmlType="submit">Submit</Button>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal
        centered
        open={redAlertUser}
        closable={true}
        footer={false}
        onCancel={() => setRedAlertUser(null)}
        // onOk={() => withdrawPas2(withdrawTxnIdSuccess)}
      >
        <Form
          layout="vertical"
          requiredMark={false}
          onFinish={(data) => handleRedHighlightUser(redAlertUser, data)}
        >
          <Form.Item
            name={"remark"}
            label="Remark*"
            rules={[{ required: true, message: "Please enter remark." }]}
          >
            <Input placeholder="Enter remark" />
          </Form.Item>
          <Row justify={"end"}>
            <Col>
              <Button htmlType="submit">Submit</Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
}

export default AllTopDepositUsers;
