import React, { useState, useEffect } from "react";
import { Row, Col, Table, message } from "antd";
import API_MANAGER from "../../API";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import CustomPaginationWithPageSize from "../../components/common/CustomPaginationWithPageSize";
import HELPERS from "../../utils/helper";
function RedeemHistory({ view_user }) {
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [page, setPage] = useState({ page: 1, limit: 20 });
  const [loading, setLoading] = useState(false);
  const getData = async () => {
    try {
      setLoading(true);
      let params = {
        ...page,
      };

      const response = await API_MANAGER.getRedeemHistory(params);
      const decryptedData = HELPERS.decrypt(response?.data?.data);
      setData(decryptedData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error("Something went wrong!");
    }
  };
  const checkkvmgatewaypay = async (order_id, order_token, payment_gatway) => {
    try {
      await API_MANAGER.checkkvmpaydeposit({
        order_id,
        order_token,
      });
      getData();
    } catch (error) {}
  };

  const checkupigatewaypay = async (order_id, order_token, pay_date) => {
    try {
      const response = await API_MANAGER.depositUpi({ order_id, order_token });
      getData();
    } catch (error) {}
  };
  const checkutrgatewaypay = async (order_id, order_token, status) => {
    try {
      await API_MANAGER.depositUTR({
        order_id,
        order_token,
        status,
      });
      getData();
    } catch (error) {
      message.error("Something went wrong");
    }
  };

  const withdrowFail = async (id) => {
    const confirm = window.confirm(
      "Are you sure, you want to update to failed this deposit?"
    );
    if (confirm) {
      try {
        await API_MANAGER.withDrawFail();
        //   baseUrl + `userdipositupdate/${id}`,
        // {
        //   status: "FAILED",
        // },
        // profle();
        // alert("deposit successfully reject");
      } catch (error) {}
    }
  };

  const checkDeposit = async (
    payment_gatway,
    order_id,
    order_token,
    pay_date,
    status
  ) => {
    //alert(payment_gatway);
    if (payment_gatway === "utr") {
      checkutrgatewaypay(order_id, order_token, status);
    } else if (payment_gatway === "upigateway") {
      checkupigatewaypay(order_id, order_token, pay_date);
    } else if (payment_gatway === "kvmgateway") {
      checkkvmgatewaypay(order_id, order_token, pay_date);
    } else if (!payment_gatway) {
      withdrowFail(order_id);
    } else {
      checkupigatewaypay(order_id, order_token, payment_gatway);
    }
  };

  useEffect(() => {
    getData();
  }, [page]);
  const newdateFormat = (e) => {
    let today = new Date(e);
    let dd = String(today?.getDate()).padStart(2, "0");
    let mm = String(today?.getMonth() + 1).padStart(2, "0"); //January is 0!
    let yyyy = today?.getFullYear();
    today = dd + "-" + mm + "-" + yyyy;
    return today;
  };
  const columns = [
    {
      title: "No.",
      dataIndex: "si",
      width: "80px",
      render: (_, row, index) => {
        return (
          <span className="cursor-pointer">
            {(page?.page - 1) * page?.limit + (index + 1)}
          </span>
        );
      },
    },
    // {
    //   title: "ID",
    //   dataIndex: "id",
    //   key: "id",
    // },
    {
      title: "Phone",
      dataIndex: "Phone",
      key: "Phone",
      render: (item, row) => (
        <span>{view_user ? row?.User_id?.Phone : "N/A"}</span>
      ),
    },
    {
      title: "User",
      dataIndex: "User",
      key: "user",
      render: (item, row) => (
        <span
          className={view_user ? "tableLink" : ""}
          onClick={() => {
            if (view_user) navigate(`/user/view/${row?.User_id?._id}`);
          }}
        >
          {row?.User_id ? row?.User_id?.Name : ""}
        </span>
      ),
    },

    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
      render: (item, row) => (
        <>
          {row?.status !== "PAID" &&
          row?.status !== "Pending" &&
          row?.status !== "FAILED" ? (
            <>
              {/* {(row?.isupdated && row?.status) || (
                <Select
                  placeholder="Select action"
                  onChange={(e) => handleFail(e, row?._id)}
                >
                  <Select.Option value="PAID">Paid</Select.Option>
                  <Select.Option value="FAILED">Fail</Select.Option>
                </Select>
              )} */}
              <span>Pending</span>
            </>
          ) : row?.status === "FAILED" ? (
            <span>FAILED</span>
          ) : (
            <span>{row?.status}</span>
          )}
        </>
      ),
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (item) => <span>{moment(item).format("LLL")}</span>,
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      render: (item, row) => (
        <span>
          {row?.status !== "PAID" && row?.status !== "FAILED" ? (
            <button
              className="btn btn-danger"
              onClick={() =>
                checkDeposit(
                  row?.payment_gatway,
                  row?.order_id,
                  row?.order_token,
                  newdateFormat(data?.createdAt)
                )
              }
            >
              Check
            </button>
          ) : (
            "Checked All"
          )}
        </span>
      ),
    },
  ];
  return (
    <div className="deposit_history">
      <Row className="pageHeading mb-20">Redeem History</Row>

      <Table
        columns={columns}
        dataSource={data?.result}
        pagination={false}
        className="table"
        rowKey={"id"}
        loading={loading}
        scroll={{
          // y: "calc(100vh - 400px)",
          x: "calc(768px)",
        }}
      />
      <CustomPaginationWithPageSize
        currentPage={page}
        setCurrentPage={setPage}
        total={data?.totalCount}
      />
    </div>
  );
}

export default RedeemHistory;
