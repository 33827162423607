import React, { useEffect, useState } from "react";
import CustomPagination from "../../components/common/CustomPagination";
import {
  Row,
  message,
  Button,
  Input,
  Select,
  DatePicker,
  Col,
  Form,
  Table,
} from "antd";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import HELPERS from "../../utils/helper";
import * as XLSX from "xlsx";
import API_MANAGER from "../../API";
function AdminPaneltyReport() {
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [page, setPage] = useState(1);
  const [adminList, setAdminList] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState(null);
  const [form] = Form.useForm();
  const getData = async () => {
    try {
      let params = {
        page: page,
        limit: 20,
      };
      if (filters) {
        params = { ...params, ...filters };
      }
      if (!filters && !startDate) {
        params = { ...params, ["FROM_DATE"]: startDate };
      }
      if (!filters && !endDate) {
        params = { ...params, ["TO_DATE"]: endDate };
      }
      if (startDate) {
        params = { ...params, ["FROM_DATE"]: startDate };
      }
      if (endDate) {
        params = { ...params, ["TO_DATE"]: endDate };
      }

      if (!filters && !startDate && !endDate) {
        params = {
          ...params,
          ["FROM_DATE"]: moment().subtract(4, "days"),
          ["TO_DATE"]: moment().add(4, "days"),
        };
      }
      setLoading(true);
      const response = await API_MANAGER.getAdminPaneltyReport(params);
      const decryptedData = HELPERS.decrypt(response?.data?.data);
      console.log(decryptedData);
      setData(decryptedData);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      message.error(error?.response?.data?.message || "Something went wrong!");
    }
  };
  const getAdminList = async () => {
    try {
      const response = await API_MANAGER.getAllAdmin();
      const decryptedData = HELPERS.decrypt(response?.data?.data);
      setAdminList(decryptedData);
    } catch (error) {
      message.error(error?.response?.data?.message || "Something went wrong!");
    }
  };

  useEffect(() => {
    getData();
  }, [page, startDate, endDate, filters]);
  useEffect(() => {
    getAdminList();
  }, []);
  let columns = [
    {
      title: "No.",
      dataIndex: "si",
      width: "80px",
      render: (_, row, index) => {
        return (
          <span className="cursor-pointer">
            {(page - 1) * 20 + (index + 1)}
          </span>
        );
      },
    },
    {
      title: "ID.",
      dataIndex: "_id",
    },
    {
      title: "Admin",
      dataIndex: "admin",
      key: "admin",
      render: (item) => <span>{item?.Name}</span>,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
    },
    {
      title: "Panelty By",
      dataIndex: "action_by",
      key: "action_by",
      render: (item) => <span>{item?.Name}</span>,
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (item) => <span>{moment(item).format("LLL")}</span>,
    },
  ];
  const handleExport = async () => {
    try {
      let formValues = form.getFieldsValue();

      let params = {
        page: 1,
        limit: 10000000,
        FROM_DATE: startDate,
        TO_DATE: endDate,
        ...formValues,
      };
      if (filters) {
        params = { ...params, ...filters };
      }
      setLoading(true);
      const response = await API_MANAGER.getAdminPaneltyReport(params);
      const decryptedData = HELPERS.decrypt(response?.data?.data);

      let table1 = [
        {
          A: "Admin",
          B: "Amount",
          C: "Reason",
          D: "Panelty By",
          E: "Date",
        },
      ];

      decryptedData?.result.forEach((row) => {
        const userDetails = row;
        table1.push({
          A: userDetails.admin?.Name,
          B: userDetails.amount,
          C: userDetails.reason,
          D: userDetails.action_by?.Name,
          E: userDetails?.createdAt
            ? moment(userDetails?.createdAt).format("LLL")
            : "",
        });
      });

      const ws = XLSX.utils.json_to_sheet(table1, {
        skipHeader: true,
      });
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
      /* generate XLSX file and send to client */
      XLSX.writeFile(wb, "adminPaneltyReport.xlsx");

      setLoading(false);
    } catch (error) {
      setLoading(false);

      message.error(error?.response?.data?.message || "Something went wrong!");
    }
  };
  return (
    <div>
      <p className="pageHeading mb-20">Admin Panelty Report</p>
      <div>
        <Form
          form={form}
          layout="vertical"
          onFinish={(values) => setFilters(values)}
        >
          <Row gutter={20} align={"middle"}>
            <Col xs={12} md={12} lg={8}>
              <Form.Item name={"amount"} label="Panelty Type">
                <Select placeholder="Select panelty type">
                  <Select.Option value={20}>
                    {`Support (Amount Rs 20)`}
                  </Select.Option>
                  <Select.Option value={100}>
                    {`Game (Amount Rs 100)`}
                  </Select.Option>
                  <Select.Option value={100}>
                    {`Other (Amount Rs 100)`}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col xs={12} md={12} lg={8}>
              <Form.Item name={"admin"} label="Select admin">
                <Select placeholder="Select admin" showSearch>
                  {adminList?.map((user) => (
                    <Select.Option value={user?._id} key={user?.id}>
                      {`${user?.Name ? user?.Name : " "} - ${user?.Phone}`}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={8}>
              <Form.Item name={"reason"} label="Remark">
                <Input placeholder="Enter reason" />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={8}>
              <Row gutter={20}>
                <Col span={12}>
                  <DatePicker
                    className="w-100"
                    onChange={(e) => setStartDate(e)}
                    placeholder="Start Date"
                  />
                </Col>
                <Col span={12}>
                  <DatePicker
                    className="w-100"
                    onChange={(e) => setEndDate(e)}
                    placeholder="End Date"
                  />
                </Col>
              </Row>
            </Col>
            <Col span={24} className="mt-16">
              <Row justify={"space-between"} gutter={[20, 20]}>
                <Col>
                  <Form.Item>
                    <Button htmlType="submit">Search</Button>
                  </Form.Item>
                </Col>
                <Col>
                  <Button
                    onClick={() => {
                      handleExport();
                    }}
                    className="primary_button"
                  >
                    Download
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>

        <div>
          <Table
            columns={columns}
            dataSource={data?.result ? data?.result : []}
            pagination={false}
            className="table"
            loading={loading}
            rowKey={"id"}
            style={{ marginTop: "24px" }}
            scroll={{
              // y: "calc(100vh - 400px)",
              x: "calc(768px)",
            }}
          />
          <CustomPagination
            currentPage={page}
            setCurrentPage={setPage}
            total={data?.totalCount}
            itemPerPage={20}
          />
        </div>
      </div>
    </div>
  );
}

export default AdminPaneltyReport;
