import React, { useState, useEffect, useCallback } from "react";
import {
  Row,
  Col,
  Table,
  Button,
  Form,
  Select,
  Input,
  message,
  Modal,
} from "antd";
import API_MANAGER from "../../API";
import CustomPaginationWithPageSize from "../../components/common/CustomPaginationWithPageSize";
import { useNavigate } from "react-router-dom";
import HELPERS from "../../utils/helper";
import debounce from "lodash.debounce";
function PenaltyBonus({ view_user, view_phone }) {
  const [data, setData] = useState();
  const [search, setSearch] = useState(null);
  const [page, setPage] = useState({ page: 1, limit: 10 });
  const [searchType, setSearchType] = useState("Name");
  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [isVisible, setIsVisible] = useState(false);
  const [type, setType] = useState(null);
  const [adminList, setAdminList] = useState([]);

  const [isCredVisible, setIsCredVisible] = useState(false);
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const navigate = useNavigate();
  const getData = async () => {
    setLoading(true);
    try {
      let params = {
        ...page,
      };
      if (search && searchType) {
        params["search"] = search;
        params["searchType"] = searchType;
      }
      const response = await API_MANAGER.getAllPenalty(params);
      const decryptedData = HELPERS.decrypt(response?.data?.data);
      setData(decryptedData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error(error?.response?.data?.message || "Something went wrong!");
    }
  };
  const fetchSearchResults = useCallback(
    debounce(async (search) => {
      if (searchType === "Phone" && search?.length < 10) {
        return;
      }
      if (!search) {
        return;
      }
      getData();
    }, 500),
    [search, searchType]
  );

  const getAdminList = async () => {
    try {
      const response = await API_MANAGER.getAllAdmin();
      const decryptedData = HELPERS.decrypt(response?.data?.data);
      setAdminList(decryptedData);
    } catch (error) {
      message.error(error?.response?.data?.message || "Something went wrong!");
    }
  };
  const handleSubmit = async (values) => {
    try {
      if (values?.type === "bonus") {
        const confirm = window.confirm(
          "Are you sure, you want to add bonus to this user?"
        );
        if (confirm) {
          await API_MANAGER.addBonus(selectedRow?._id, {
            bonus: JSON.parse(values?.number),
            txn_msg: values?.reason,
            bonus_type: values?.bonus_type,
            panelty_admin: values?.panelty_admin,
            UTR_number: values?.UTR_number,
          });
          setIsVisible(false);
          form.resetFields();
          setSelectedRow({});
          getData();
        }
      } else if (values?.type === "penalty") {
        const confirm2 = window.confirm(
          "Are you sure, you want to add penalty to this user?"
        );
        if (confirm2) {
          await API_MANAGER.addPenalty(selectedRow?._id, {
            bonus: JSON.parse(values?.number),
            txn_msg: values?.reason,
          });
          setIsVisible(false);
          form.resetFields();
          setSelectedRow({});
          getData();
        }
      }
    } catch (error) {
      message.error("Something went wrong!");
    }
  };
  const handleCredSubmit = async (values) => {
    try {
      if (values?.id === "StarLudo" && values?.password === "StarLudo@M2024") {
        setIsCredVisible(false);
        setIsVisible(true);
        form2.resetFields();
      } else {
        message.error("Please enter valid credentials!");
      }
    } catch (error) {
      message.error("Something went wrong!");
    }
  };

  useEffect(() => {
    fetchSearchResults(search);
    return fetchSearchResults.cancel; // Cancel debounce on unmount
  }, [search, searchType, page, fetchSearchResults]);

  const columns = [
    {
      title: "No.",
      dataIndex: "si",
      width: "80px",
      render: (_, row, index) => {
        return (
          <span className="cursor-pointer">
            {(page?.page - 1) * page?.limit + (index + 1)}
          </span>
        );
      },
    },

    {
      title: "Name",
      dataIndex: "Name",
      key: "Name",
      render: (item, row) => (
        <span>
          <span
            className={view_user ? "tableLink" : ""}
            onClick={() => {
              if (view_user) {
                navigate(`/user/view/${row?._id}`);
              }
            }}
          >
            {item}
          </span>
          {view_user ? (
            <>
              <br />
              {view_phone ? row?.Phone : ""}
            </>
          ) : (
            ""
          )}
        </span>
      ),
    },

    {
      title: "Balance",
      dataIndex: "Wallet_balance",
      key: "Wallet_balance",
      render: (item) => <span>{item.toFixed(2)}</span>,
    },

    {
      title: "Action",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (item, row) => (
        <Button
          className="secondary_button"
          onClick={() => {
            setSelectedRow(row);
            setIsCredVisible(true);
          }}
        >
          Action
        </Button>
      ),
    },
  ];
  return (
    <div className="deposit_history">
      <Modal
        title="Penalty & Bonus"
        footer={false}
        centered
        open={isVisible}
        onCancel={() => {
          form.resetFields();
          setIsVisible(false);
          setSelectedRow({});
        }}
      >
        <Form form={form} onFinish={handleSubmit}>
          <Form.Item
            name="type"
            rules={[
              {
                required: true,
                message: "Please select type.",
              },
            ]}
          >
            <Select placeholder="Select type">
              <Select.Option value="penalty">Penalty</Select.Option>
              <Select.Option value="bonus">Bonus</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="bonus_type"
            rules={[
              {
                required: true,
                message: "Please select type.",
              },
            ]}
          >
            <Select
              placeholder="Select type"
              onChange={(e) => {
                if (e === "game") {
                  getAdminList();
                }
                setType(e);
              }}
            >
              <Select.Option value="cash">Cash</Select.Option>
              <Select.Option value="game">Game fault</Select.Option>
              <Select.Option value="gateway">Gateway Update</Select.Option>
              <Select.Option value="other">Other</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="number"
            rules={[
              {
                required: true,
                message: "Please enter amount.",
              },
            ]}
          >
            <Input type="number" max={250000} placeholder="Enter Amount" />
          </Form.Item>{" "}
          {type === "game" && (
            <Form.Item
              name="panelty_admin"
              rules={[
                {
                  required: true,
                  message: "Please select admin",
                },
              ]}
            >
              <Select placeholder="Select Admin" showSearch>
                {adminList?.map((user) => (
                  <Select.Option value={user?._id} key={user?.id}>
                    {`${user?.Name ? user?.Name : " "} - ${user?.Phone}`}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
          {type === "gateway" && (
            <Form.Item
              name="UTR_number"
              rules={[
                {
                  required: true,
                  message: "Please enter utr number",
                },
              ]}
            >
              <Input placeholder="please enter UTR Number" />
            </Form.Item>
          )}
          {(type === "game" || type === "other" || type === "gateway") && (
            <Form.Item
              name="reason"
              rules={[
                {
                  required: true,
                  message: "Please enter reason.",
                },
              ]}
            >
              <Input placeholder="Enter reason" />
            </Form.Item>
          )}
          <Form.Item>
            <Button htmlType="submit">Submit</Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Credentials"
        footer={false}
        centered
        open={isCredVisible}
        onCancel={() => {
          form2.resetFields();
          setIsCredVisible(false);
        }}
      >
        <Form form={form2} onFinish={handleCredSubmit}>
          <Form.Item
            name="id"
            rules={[
              {
                required: true,
                message: "Please select type.",
              },
            ]}
          >
            <Input placeholder="" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please enter amount.",
              },
            ]}
          >
            <Input.Password type="password" placeholder="Enter password" />
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit">Submit</Button>
          </Form.Item>
        </Form>
      </Modal>
      <Row className="pageHeading">Penalty Bonus</Row>
      <Form layout="vertical">
        <Row gutter={24} align={"middle"}>
          <Col xs={12} lg={6}>
            <Form.Item label="Search By" name={"searchType"}>
              <Select
                // className="selectBox"
                onChange={(e) => setSearchType(e)}
                placeholder="Search By"
              >
                <Select.Option value="Name">Name</Select.Option>
                <Select.Option value="Phone">Phone</Select.Option>
                {/* <Select.Option value="_id">User ID</Select.Option> */}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={12} lg={6}>
            <Form.Item label="Search " name={"search"}>
              <Input
                placeholder="Search"
                // className="inputBox"
                onChange={(e) => setSearch(e?.target?.value)}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Table
        columns={columns}
        dataSource={data?.result}
        pagination={false}
        className="table"
        rowKey={"id"}
        loading={loading}
        scroll={{
          // y: "calc(100vh - 400px)",
          x: "calc(268px + 40%)",
        }}
      />
      <CustomPaginationWithPageSize
        currentPage={page}
        setCurrentPage={setPage}
        total={data?.totalCount}
      />
    </div>
  );
}

export default PenaltyBonus;
