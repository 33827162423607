import React, { useEffect, useState } from "react";
import {
  DatePicker,
  Row,
  Table,
  message,
  Col,
  Form,
  Input,
  Button,
  Select,
} from "antd";
import API_MANAGER from "../../API";
import moment from "moment";
import CustomPagination from "./CustomPagination";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import HELPERS from "../../utils/helper";

function ReportByStatus({ status, view_user, view_phone }) {
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState(null);
  const [form] = Form.useForm();
  const getData = async () => {
    try {
      let params = {
        page: page,
        limit: 10,

        req_type: status,
      };
      if (filters) {
        params = { ...params, ...filters };
      }
      if (!filters && !startDate) {
        params = { ...params, ["FROM_DATE"]: startDate };
      }
      if (!filters && !endDate) {
        params = { ...params, ["TO_DATE"]: endDate };
      }
      if (startDate) {
        params = { ...params, ["FROM_DATE"]: startDate };
      }
      if (endDate) {
        params = { ...params, ["TO_DATE"]: endDate };
      }

      if (!filters && !startDate && !endDate) {
        params = {
          ...params,
          ["FROM_DATE"]: moment().subtract(4, "days"),
          ["TO_DATE"]: moment().add(4, "days"),
        };
      }
      setLoading(true);
      const response = await API_MANAGER.getWithdrawalReport(params);
      const decryptedData = HELPERS.decrypt(response?.data?.data);
      setData(decryptedData);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      message.error(error?.response?.data?.message || "Something went wrong!");
    }
  };

  useEffect(() => {
    if ((startDate && endDate) || filters) getData();
  }, [page, startDate, endDate, filters]);
  let columns = [
    {
      title: "No.",
      dataIndex: "si",
      width: "80px",
      render: (_, row, index) => {
        return (
          <span className="cursor-pointer">
            {(page - 1) * 20 + (index + 1)}
          </span>
        );
      },
    },

    {
      title: "Phone",
      dataIndex: "Phone",
      key: "Phone",
      render: (item, row) => (
        <span>{view_phone ? row?.User_id?.Phone : "N/A"}</span>
      ),
    },
    {
      title: "User",
      dataIndex: "User",
      key: "User",
      render: (item, row) => (
        <span
          className={view_user ? "tableLink" : ""}
          onClick={() => {
            if (view_user) navigate(`/user/view/${row?.User_id?._id}`);
          }}
        >
          {row?.User_id ? row?.User_id?.Name : ""}
        </span>
      ),
    },
    {
      title: "Type",
      dataIndex: "Withdraw_type",
      key: "Withdraw_type",
      render: (item, row) => (
        <span>
          {row?.Req_type === "deposit"
            ? row?.payment_gatway
            : row?.Withdraw_type}
        </span>
      ),
    },

    {
      title: "UPI",
      dataIndex: "upi_id",
      key: "upi_id",
      render: (item, row) => (
        <span>{row?.upi_id ? row?.upi_id : row?.User_id?.upi_id}</span>
      ),
    },

    {
      title: status === "WITHDRAW" ? "Order ID" : "UTR No.",
      dataIndex: "UTR_number",
      key: "UTR_number",
      render: (item, row) => <span>{row?.UTR_number}</span>,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (item) => <span> {item ? item : "Proccessing"}</span>,
    },
    {
      title: "Remark",
      dataIndex: "txn_msg",
      key: "txn_msg",
      render: (item) => <span> {item}</span>,
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (item) => <span>{moment(item).format("LLL")}</span>,
    },
    {
      title: "scannerName",
      dataIndex: "scannerName",
      key: "scannerName",
    },
    {
      title: "Action By",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (item, row) => (
        <span>{row?.action_by ? row?.action_by?.Name : "N/A"}</span>
      ),
    },
  ];
  if (status === "PENALTY" || status === "BONUS") {
    columns.push({
      title: "Bonus/Panelty Type",
      dataIndex: "bonus_type",
      key: "bonus_type",
    });
    columns.push({
      title: "Against Admin",
      dataIndex: "panelty_admin",
      key: "panelty_admin",
      render: (item) => <span> {item?.Name}</span>,
    });
  }
  const handleExport = async () => {
    if (startDate && endDate) {
      try {
        let formValues = form.getFieldsValue();

        let params = {
          page: 1,
          limit: 100000000,
          FROM_DATE: startDate,
          TO_DATE: endDate,
          req_type: status,
          ...formValues,
        };
        if (filters) {
          params = { ...params, ...filters };
        }
        setLoading(true);
        const response = await API_MANAGER.getWithdrawalReport(params);
        const decryptedData = HELPERS.decrypt(response?.data?.data);

        if (status === "WITHDRAW" || status === "MANUALWITHDRAW") {
          const reportdata = decryptedData?.result?.map((e) => {
            return {
              ID: e?._id,
              Name: e?.User_id?.Name,
              Phone: e?.User_id?.Phone,
              Amount: e?.amount,
              OrderID: e?.UTR_number,
              UpiID: e?.upi_id,
              Status: e?.status,
              ActionBy: e?.action_by?.Name,
              Date: e?.createdAt ? moment(e?.createdAt).format("LLL") : "",
            };
          });
          const worksheet = XLSX.utils.json_to_sheet(reportdata);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(
            workbook,
            worksheet,
            "Withdrawal Report"
          );
          XLSX.writeFile(workbook, "WithdrawalReport.xlsx");
        } else if (status === "DEPOSIT") {
          const reportdata = decryptedData?.result?.map((e) => {
            return {
              ID: e?._id,
              Name: e?.User_id?.Name,
              Phone: e?.User_id?.Phone,
              Amount: e?.amount,
              UTR: e?.UTR_number,
              Status: e?.status,
              ActionBy: e?.action_by?.Name,
              Date: e?.createdAt ? moment(e?.createdAt).format("LLL") : "",
              Remark: e?.txn_msg,
              Type: e?.payment_gatway,
              ScannerName: e?.scannerName,
            };
          });
          const worksheet = XLSX.utils.json_to_sheet(reportdata);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, "Deposit Report");
          XLSX.writeFile(workbook, "DepositReport.xlsx");
        } else if (status === "BONUS") {
          const reportdata = decryptedData?.result?.map((e) => {
            return {
              ID: e?._id,
              Name: e?.User_id?.Name,
              Phone: e?.User_id?.Phone,
              Amount: e?.amount,
              Status: e?.status,
              ActionBy: e?.action_by?.Name,
              Date: e?.createdAt ? moment(e?.createdAt).format("LLL") : "",
              Remark: e?.txn_msg,
              Type: e?.bonus_type,
              UTR: e?.UTR_number,
              AdminAgainst: e?.panelty_admin?.Name,
            };
          });
          const worksheet = XLSX.utils.json_to_sheet(reportdata);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, "Bonus Report");
          XLSX.writeFile(workbook, "BonusReport.xlsx");
        } else if (status === "PENALTY") {
          const reportdata = decryptedData?.result?.map((e) => {
            return {
              ID: e?._id,
              Name: e?.User_id?.Name,
              Phone: e?.User_id?.Phone,
              Amount: e?.amount,
              Status: e?.status,
              ActionBy: e?.action_by?.Name,
              Date: e?.createdAt ? moment(e?.createdAt).format("LLL") : "",
              Remark: e?.txn_msg,
              Type: e?.bonus_type,
              AdminAgainst: e?.panelty_admin?.Name,
            };
          });
          const worksheet = XLSX.utils.json_to_sheet(reportdata);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, "Panelty Report");
          XLSX.writeFile(workbook, "PaneltyReport.xlsx");
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        message.error(
          error?.response?.data?.message || "Something went wrong!"
        );
      }
    } else {
      setLoading(false);

      message.error("Please select start and end date.");
    }
  };
  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        onFinish={(values) => setFilters(values)}
      >
        <Row gutter={20} align={"middle"}>
          {(status === "DEPOSIT" || status === "WITHDRAW") && (
            <Col xs={12} md={12} lg={8}>
              <Form.Item
                name={"UTR_number"}
                label={`Search by ${
                  status === "WITHDRAW" ? "Order ID" : "UTR No."
                }`}
              >
                <Input
                  placeholder={`Enter ${
                    status === "WITHDRAW" ? "Order ID" : "UTR No."
                  }`}
                />
              </Form.Item>
            </Col>
          )}
          {status === "DEPOSIT" && (
            <Col xs={12} md={12} lg={8}>
              <Form.Item name={"scannerName"} label={`Search by Scanner Name`}>
                <Input placeholder={`Enter Scanner Name`} />
              </Form.Item>
            </Col>
          )}

          <Col xs={12} md={12} lg={8}>
            <Form.Item name={"amount"} label="Search by amount">
              <Input placeholder="Enter amount" />
            </Form.Item>
          </Col>
          {(status === "DEPOSIT" ||
            status === "WITHDRAW" ||
            status === "MANUALWITHDRAW") && (
            <Col xs={12} md={12} lg={8}>
              <Form.Item name={"_id"} label="Search by ID">
                <Input placeholder="Enter ID" />
              </Form.Item>
            </Col>
          )}

          {(status === "DEPOSIT" ||
            status === "WITHDRAW" ||
            status === "MANUALWITHDRAW") && (
            <Col xs={12} md={12} lg={8}>
              <Form.Item name={"status"} label="Select status">
                <Select placeholder="Select Status">
                  <Select.Option
                    value={status === "DEPOSIT" ? "PAID" : "SUCCESS"}
                  >
                    Paid / Success
                  </Select.Option>
                  <Select.Option value={"Pending"}>Pending</Select.Option>
                  <Select.Option value={"FAILED"}>Failed</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          )}
          {/* {status === "DEPOSIT" && (
            <Col xs={12} md={12} lg={8}>
              <Form.Item name={"status"} label="Select deposit type">
                <Select placeholder="Select deposit type">
                  <Select.Option value={"FAILED"}>UPI Gateway</Select.Option>
                  <Select.Option value={"Pending"}>TMPAY</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          )} */}

          <Col xs={24} md={12} lg={8}>
            <Row gutter={20}>
              <Col span={12}>
                <DatePicker
                  className="w-100"
                  onChange={(e) => setStartDate(e)}
                  placeholder="Start Date"
                />
              </Col>
              <Col span={12}>
                <DatePicker
                  className="w-100"
                  onChange={(e) => setEndDate(e)}
                  placeholder="End Date"
                />
              </Col>
            </Row>
          </Col>
          <Col span={24} className="mt-16">
            <Row justify={"space-between"} gutter={[20, 20]}>
              <Col>
                <Form.Item>
                  <Button htmlType="submit">Search</Button>
                </Form.Item>
              </Col>
              <Col>
                <Button
                  onClick={() => {
                    handleExport();
                  }}
                  className="primary_button"
                >
                  Download
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>

      <div>
        <Table
          columns={columns}
          dataSource={data?.result ? data?.result : []}
          pagination={false}
          className="table"
          loading={loading}
          rowKey={"id"}
          style={{ marginTop: "24px" }}
          scroll={{
            // y: "calc(100vh - 400px)",
            x: "calc(768px)",
          }}
        />
        <CustomPagination
          currentPage={page}
          setCurrentPage={setPage}
          total={data?.totalCount}
          itemPerPage={10}
        />
      </div>
    </div>
  );
}

export default ReportByStatus;
