import moment from "moment";
import React, { useState, useEffect } from "react";
import API_MANAGER from "../../../API";
import { Table, message } from "antd";
import CustomPagination from "../CustomPagination";
import HELPERS from "../../../utils/helper";

function UserDepositHistory({ id, type }) {
  const [page, setPage] = useState(1);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const getData = async () => {
    const params = {
      limit: 20,
      page: page,
      req_type: type,
    };
    try {
      setLoading(true);
      const response = await API_MANAGER.getDepositHistoryById(id, params);
      const decryptedData = HELPERS.decrypt(response?.data?.data);
      setData(decryptedData);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      message.error(error?.response?.data?.message || "Something went wrong!");
    }
  };
  const newdateFormat = (e) => {
    let today = new Date(e);
    let dd = String(today?.getDate()).padStart(2, "0");
    let mm = String(today?.getMonth() + 1).padStart(2, "0"); //January is 0!
    let yyyy = today?.getFullYear();
    today = dd + "-" + mm + "-" + yyyy;
    return today;
  };
  const checkkvmgatewaypay = async (order_id, order_token, payment_gatway) => {
    try {
      await API_MANAGER.checkkvmpaydeposit({
        order_id,
        order_token,
      });
      getData();
    } catch (error) {}
  };
  const checkupigatewaypay = async (order_id, order_token, pay_date) => {
    try {
      const response = await API_MANAGER.depositUpi({
        order_id,
        order_token,
      });
      getData();
    } catch (error) {}
  };
  const checkupitmpaygatewaypay = async (order_id, order_token, pay_date) => {
    try {
      const response = await API_MANAGER.depositUpiTmapy({
        order_id,
        order_token,
      });
      getData();
    } catch (error) {}
  };
  const checkutrgatewaypay = async (order_id, order_token, status) => {
    try {
      await API_MANAGER.depositUTR({
        order_id,
        order_token,
        status,
      });
      getData();
    } catch (error) {
      message.error("Something went wrong");
    }
  };
  const withdrowFail = async (id) => {
    const confirm = window.confirm(
      "Are you sure, you want to update to failed this deposit?"
    );
    if (confirm) {
      try {
        const response = await API_MANAGER.withDrawFail();
        //   baseUrl + `userdipositupdate/${id}`,
        // {
        //   status: "FAILED",
        // },
        // profle();
        // alert("deposit successfully reject");
      } catch (error) {}
    }
  };

  const checkDeposit = async (
    payment_gatway,
    order_id,
    order_token,
    pay_date,
    status
  ) => {
    //alert(payment_gatway);
    if (payment_gatway === "utr") {
      checkutrgatewaypay(order_id, order_token, status);
    } else if (payment_gatway === "upigateway") {
      checkupigatewaypay(order_id, order_token, pay_date);
    } else if (payment_gatway === "depositTMPAYUPI") {
      checkupitmpaygatewaypay(order_id, order_token, pay_date);
    } else if (payment_gatway === "kvmgateway") {
      checkkvmgatewaypay(order_id, order_token, pay_date);
    } else if (!payment_gatway) {
      withdrowFail(order_id);
    } else {
      checkupigatewaypay(order_id, order_token, payment_gatway);
    }
  };
  useEffect(() => {
    if (id) getData();
  }, [id, page]);
  const columns = [
    {
      title: "No.",
      dataIndex: "si",
      width: "80px",
      render: (_, row, index) => {
        return (
          <span className="cursor-pointer">
            {(page - 1) * 20 + (index + 1)}
          </span>
        );
      },
    },
    {
      title: "ID",
      dataIndex: "_id",
      key: "_id",
    },

    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Closing Balance",
      dataIndex: "closing_balance",
      key: "closing_balance",
      render: (item) => <span>{item?.toFixed(2)}</span>,
    },
    {
      title: "Type",
      dataIndex: "payment_gatway",
      key: "payment_gatway",
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Remark",
      dataIndex: "txn_msg",
      key: "txn_msg",
    },
    {
      title: "Action By",
      dataIndex: "action_by",
      key: "action_by",
      render: (item, row) => (
        <span>
          {`${row?.action_by?.Phone ? row?.action_by?.Phone : ""}`}
          {row?.action_by?.Name ? `(${row?.action_by?.Name})` : ""}
        </span>
      ),
    },
    {
      title: "Date",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (item) => <span>{moment(item).format("LLL")}</span>,
    },
    {
      title: "Scanner Name",
      dataIndex: "scannerName",
      key: "scannerName",
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      render: (item, row) => (
        <>
          {type === "DEPOSIT" && (
            <span>
              {row?.status !== "PAID" && row?.status !== "FAILED" ? (
                <button
                  className="btn btn-danger"
                  onClick={() =>
                    checkDeposit(
                      row?.payment_gatway,
                      row?.order_id,
                      row?.order_token,
                      newdateFormat(data?.createdAt)
                    )
                  }
                >
                  Check
                </button>
              ) : (
                "Checked All"
              )}
            </span>
          )}
        </>
      ),
    },
  ];
  return (
    <div>
      <Table
        columns={columns}
        dataSource={data?.result}
        pagination={false}
        className="table"
        rowKey={"id"}
        loading={loading}
        scroll={{
          //   y: "calc(100vh - 400px)",
          x: "calc(768px + 40%)",
        }}
      />
      <CustomPagination
        currentPage={page}
        setCurrentPage={setPage}
        total={data?.totalCount}
        itemPerPage={20}
      />
    </div>
  );
}

export default UserDepositHistory;
