import React, { useState, useEffect } from "react";
import { Row, Col, Table, message } from "antd";
import API_MANAGER from "../../API";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import CustomPaginationWithPageSize from "../../components/common/CustomPaginationWithPageSize";
import HELPERS from "../../utils/helper";
function WithdrawalHistory({ view_user }) {
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [page, setPage] = useState({ page: 1, limit: 20 });
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    setLoading(true);
    try {
      let params = {
        ...page,
      };
      const response = await API_MANAGER.getWithdrawHistory(params);
      const decryptedData = HELPERS.decrypt(response?.data?.data);
      setData(decryptedData);
      console.log(decryptedData,':eddd')
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error(error?.response?.data?.message || "Something went wrong!");
    }
  };

  useEffect(() => {
    getData();
  }, [page]);
  const columns = [
    {
      title: "No.",
      dataIndex: "si",
      width: "80px",
      render: (_, row, index) => {
        return (
          <span className="cursor-pointer">
            {(page?.page - 1) * page?.limit + (index + 1)}
          </span>
        );
      },
    },
    {
      title: "ID",
      dataIndex: "_id",
      key: "_id",
    },
    {
      title: "Phone",
      dataIndex: "Phone",
      key: "Phone",
      render: (item, row) => (
        <span>{view_user ? row?.User_id?.Phone : "N/A"}</span>
      ),
    },
    {
      title: "User",
      dataIndex: "User",
      key: "User",
      render: (item, row) => (
        <span
          className={view_user ? "tableLink" : ""}
          onClick={() => {
            if (view_user) navigate(`/user/view/${row?.User_id?._id}`);
          }}
        >
          {row?.User_id ? row?.User_id?.Name : ""}
        </span>
      ),
    },
    {
      title: "Type",
      dataIndex: "Withdraw_type",
      key: "Withdraw_type",
    },
    {
      title: "UPI",
      dataIndex: "upi_id",
      key: "upi_id",
      render: (item, row) => <span>{row?.upi_id}</span>,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Order ID",
      dataIndex: "UTR_number",
      key: "UTR_number",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (item) => <span> {item ? item : "Proccessing"}</span>,
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (item) => <span>{moment(item).format("LLL")}</span>,
    },
    {
      title: "Action By",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (item, row) => (
        <span>{row?.action_by ? row?.action_by?.Name : "N/A"}</span>
      ),
    },
  ];
  return (
    <div className="deposit_history">
      <Row className="pageHeading mb-20">Withdrawl History</Row>

      <Table
        columns={columns}
        dataSource={data?.result}
        pagination={false}
        className="table"
        rowKey={"id"}
        loading={loading}
        scroll={{
          // y: "calc(100vh - 400px)",
          x: "calc(768px)",
        }}
      />
      <CustomPaginationWithPageSize
        currentPage={page}
        setCurrentPage={setPage}
        total={data?.totalCount}
      />
    </div>
  );
}

export default WithdrawalHistory;
